import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
const abortController = new AbortController();
import alert from 'stores/alert';

import { onError } from '@apollo/client/link/error';
import { GraphQLClient } from 'graphql-request';
import {
  getSdk as getServerSDK,
  SdkFunctionWrapper,
} from './server/__generated__/request';

function generateApolloClient(uri: string) {
  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: ApolloLink.from([
      //   authMiddleware,

      onError(({ graphQLErrors, networkError }) => {
        if (networkError) {
          setTimeout(() => {
            alert.error({
              title: 'Network error',
              text: networkError?.message || 'Something went wrong.',
            });
          }, 0);
          console.error(`[Network error]: ${networkError}`);
        }

        if (graphQLErrors) {
          setTimeout(() => {
            alert.error({
              title: 'Error',
              text: graphQLErrors[0]?.message || 'Something went wrong.',
            });
          }, 0);
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
      }),
      new HttpLink({
        uri: uri,
        fetchOptions: {
          signal: abortController.signal,
        },
      }),
    ]),
  });
}

export const serverApolloClient = generateApolloClient(
  process.env.REACT_APP_SERVER_URI || '',
);

const sdkWrapper: SdkFunctionWrapper = async (
  action: () => Promise<any>,
  operationName: string,
  operationType?: string,
  variables?: any,
): Promise<any> => {
  return action().catch((error) => {
    if (variables?._notCatchedBefore) {
      throw error;
    } else {
      console.error('query error, method:', operationName, variables || '');
      console.log(JSON.stringify(error));
      setTimeout(() => {
        alert.error({
          title: 'Error',
          text: error?.response?.errors?.length
            ? error?.response?.errors[0]?.message
            : 'Something went wrong.',
        });
      }, 0);
    }
  });
};

export const serverRequestApi = getServerSDK(
  new GraphQLClient(process.env.REACT_APP_SERVER_URI || ''),
  sdkWrapper,
);
