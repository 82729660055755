import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { HeaderLogoSvg } from 'assets/img';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  gap: 37px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  gap: 40px;
`;

const BgBox = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Logo = styled(HeaderLogoSvg)`
  width: 372px;
  height: auto;
  z-index: 9999;
`;

const MobileLogo = styled(HeaderLogoSvg)`
  width: 260px;
  height: auto;
  z-index: 9999;
`;

const DescText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  z-index: 9999;
`;

const MobileDescText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  z-index: 9999;
`;

const ContentText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  line-height: 108px;
  z-index: 9999;
`;

const MobileContentText = styled(Typography)`
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  z-index: 9999;
`;

export default function TopModules() {
  return isMobile ? (
    <MobileWrapper>
      <BgBox>
        <video
          src={'/video/header.mp4'}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
          }}
        />
      </BgBox>
      <MobileLogo />
      <MobileDescText>
        NFT-Centric Blockchain
        <br />
        An Open Network for
        <br />
        Verified AI Assets
      </MobileDescText>
      <MobileContentText>「Coming soon」</MobileContentText>
    </MobileWrapper>
  ) : (
    <Wrapper>
      <BgBox>
        <video
          src={'/video/header.mp4'}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
          }}
        />
      </BgBox>
      <Logo />
      <DescText>
        NFT-Centric Blockchain
        <br />
        An Open Network for Verified AI Assets
      </DescText>
      <ContentText>「Coming soon」</ContentText>
    </Wrapper>
  );
}
