import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import GlobalSimpleDialog from 'components/Dialog/GlobalSimpleDialog';
import GlobalAlert from 'components/Alert/GlobalAlert';

import LandingPage from 'pages/landingPage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* height: 100%; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;

  overflow-x: hidden;
  box-sizing: border-box;

  background: #000;

  &.white {
    background-color: #fff;
  }

  position: relative;
  z-index: 1;
`;

function App() {
  return (
    <Wrapper>
      <LandingPage />
    </Wrapper>
  );
}

export default observer(App);
